import axios from 'axios';
export const server = axios.create({
  // baseURL: 'http://192.168.1.25:5001',
  // baseURL: 'http://192.168.0.113:5001',
  // baseURL: 'http://192.168.0.109:80',
  // baseURL: 'http://localhost:5001',
  // baseURL: 'https://api.realx.in/',
  baseURL: 'https://stagingapi.realx.in/',
  responseType: 'json',
  headers: {
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // Authorization: authHeader(),
  },
});